// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-page-tsx": () => import("../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-keypoint-page-tsx": () => import("../src/templates/keypoint-page.tsx" /* webpackChunkName: "component---src-templates-keypoint-page-tsx" */),
  "component---src-templates-job-opening-page-tsx": () => import("../src/templates/job-opening-page.tsx" /* webpackChunkName: "component---src-templates-job-opening-page-tsx" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-anfahrt-tsx": () => import("../src/pages/anfahrt.tsx" /* webpackChunkName: "component---src-pages-anfahrt-tsx" */),
  "component---src-pages-index-tsx": () => import("../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-offnungszeiten-tsx": () => import("../src/pages/öffnungszeiten.tsx" /* webpackChunkName: "component---src-pages-offnungszeiten-tsx" */)
}

